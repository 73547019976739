<template>
  <el-dialog
      :title="!dataForm.id ? `新增${sourceType}数据源` : `修改${sourceType}数据源`"
      :close-on-click-modal="false"
      :visible.sync="visible">
    <el-form :model="dataForm" :rules="dbDataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px" v-if="sourceType == '数据库'">
      <el-form-item label="数据库类型" prop="linkDBType">
        <el-select v-model="dataForm.linkDBType" placeholder="请选择数据库类型">
          <el-option
              v-for="item in linkDBOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="数据库IP" prop="ip">
        <el-input v-model="dataForm.ip" placeholder="请输入数据库IP"></el-input>
      </el-form-item>
      <el-form-item label="端口" prop="port">
        <el-input v-model="dataForm.port" placeholder="请输入数据库端口"></el-input>
      </el-form-item>
      <el-form-item label="数据源名称" prop="alias">
        <el-input v-model="dataForm.alias" placeholder="请输入数据源名称"></el-input>
      </el-form-item>
      <el-form-item label="数据库名称" prop="dbname">
        <el-input v-model="dataForm.dbname" placeholder="请输入数据库名称"></el-input>
      </el-form-item>
      <el-form-item label="数据库用户名" prop="username">
      <el-input v-model="dataForm.username" placeholder="请输入数据库用户名"></el-input>
      </el-form-item>
      <el-form-item label="数据库密码" prop="password">
        <el-input v-model="dataForm.password" placeholder="请输入数据库密码" type="password"></el-input>
      </el-form-item>
      <el-form-item label="负责人邮箱" prop="email">
        <el-input v-model="dataForm.email" placeholder="邮箱"></el-input>
      </el-form-item>
      <el-form-item label="负责人手机号" prop="mobile">
        <el-input v-model="dataForm.mobile" placeholder="手机号"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-if="sourceType == '数据库'">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="success" @click="linkDBTest">测试链接</el-button>
      <el-button type="primary" @click="dataFormSubmit">确 定</el-button>
    </span>

    <el-form :model="dataForm" :rules="fileDataRule" ref="dataForm" label-width="80px" v-if="sourceType == '文件'">
      <el-form-item label="数据源名称" prop="alias">
        <el-input v-model="dataForm.alias" placeholder="请输入数据源名称"></el-input>
      </el-form-item>
      <el-form-item label="文件" prop="file" v-if="dataForm.id == 0">
        <el-upload
            class="upload-demo"
            action="/bigdata/addDataAccessTask/fileUpload"
            accept=".txt,.doc,.docx,.xls,.xlsx"
            :before-remove="beforeRemove"
            :before-upload="beforeUpload"
            :on-success="upFileSuccess"
            :file-list="fileList">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传txt/word/excel文件</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="负责人邮箱" prop="email">
        <el-input v-model="dataForm.email" placeholder="邮箱"></el-input>
      </el-form-item>
      <el-form-item label="负责人手机号" prop="mobile">
        <el-input v-model="dataForm.mobile" placeholder="手机号"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-if="sourceType == '文件'">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="dataFormSubmit">确 定</el-button>
    </span>

    <el-form :model="dataForm" :rules="sensorDataRule" ref="dataForm" label-width="80px" v-if="sourceType == '传感器'">
      <el-form-item label="数据源名称" prop="alias">
        <el-input v-model="dataForm.alias" placeholder="请输入数据源名称"></el-input>
      </el-form-item>
      <el-form-item label="传感器名称" v-if="dataForm.id == 0">
        <el-input v-model="dataForm.name" placeholder="请输入传感器名称"></el-input>
      </el-form-item>
      <el-form-item
          v-for="(data, index) in dataForm.dataList"
          :label="'数据列' + index"
          :key="data.key"
          v-if="dataForm.id == 0"
      >
        <el-input v-model="data.value" placeholder="请输入数据列名"></el-input>
        <el-button @click.prevent="removeData(data)">删除</el-button>
      </el-form-item>
      <el-form-item  v-if="dataForm.id == 0">
        <el-button @click="addData">新增数据列</el-button>
      </el-form-item>
      <el-form-item label="负责人邮箱" prop="email">
        <el-input v-model="dataForm.email" placeholder="邮箱"></el-input>
      </el-form-item>
      <el-form-item label="负责人手机号" prop="mobile">
        <el-input v-model="dataForm.mobile" placeholder="手机号"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-if="sourceType == '传感器'">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="dataFormSubmit">确 定</el-button>
    </span>

    <el-form :model="dataForm" :rules="tdDataRule" ref="dataForm" label-width="80px" v-if="sourceType == '第三方'">
      <el-form-item label="数据源名称">
        <el-input v-model="dataForm.alias" placeholder="请输入数据源名称"></el-input>
      </el-form-item>
      <el-form-item label="第三方名称" v-if="dataForm.id == 0">
        <el-input v-model="dataForm.name" placeholder="请输入第三方名称"></el-input>
      </el-form-item>
      <el-form-item
          v-for="(data, index) in dataForm.dataList"
          :label="'数据列' + index"
          :key="data.key"
          v-if="dataForm.id == 0"
      >
        <el-input v-model="data.value" placeholder="请输入数据列名"></el-input>
        <el-button @click.prevent="removeData(data)">删除</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="addData" v-if="dataForm.id == 0">新增数据列</el-button>
      </el-form-item>
      <el-form-item label="负责人邮箱" prop="email">
        <el-input v-model="dataForm.email" placeholder="邮箱"></el-input>
      </el-form-item>
      <el-form-item label="负责人手机号" prop="mobile">
        <el-input v-model="dataForm.mobile" placeholder="手机号"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-if="sourceType == '第三方'">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="dataFormSubmit">确 定</el-button>
    </span>

    <el-form :model="dataForm" :rules="opcRule" ref="dataForm" label-width="80px" v-if="sourceType == 'opc'">
      <el-form-item label="IP" prop="ip">
        <el-input v-model="dataForm.ip" placeholder="请输入IP"></el-input>
      </el-form-item>
      <el-form-item label="端口" prop="port">
        <el-input v-model="dataForm.port" placeholder="请输入端口"></el-input>
      </el-form-item>
      <el-form-item label="数据源名称" prop="alias">
        <el-input v-model="dataForm.alias" placeholder="请输入数据源名称"></el-input>
      </el-form-item>
<!--      <el-form-item label="用户名" prop="username">-->
<!--        <el-input v-model="dataForm.username" placeholder="请输入用户名"></el-input>-->
<!--      </el-form-item>-->
<!--      选择证书登录或密码登录-->
      <el-form-item label="登录方式" prop="linkOpcType">
        <el-select v-model="dataForm.linkOpcType" placeholder="请选择数据库类型">
          <el-option
                  v-for="item in linkOpcOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
<!--      <el-form-item label="密码" prop="password" v-if="dataForm.linkOpcType==0">-->
<!--        <el-input v-model="dataForm.password" placeholder="请输入密码" type="password"></el-input>-->
<!--      </el-form-item>-->
      <el-form-item label="证书" prop="file" v-if="dataForm.id == 0 && dataForm.linkOpcType==1" >
        <el-upload
                class="upload-demo"
                action="/bigdata/addDataAccessTask/pfxFileUpload"
                accept=".pfx"
                :before-remove="beforeRemovePfx"
                :before-upload="beforeUploadPfx"
                :on-success="upFileSuccess"
                :file-list="fileList">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传pfx文件</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="负责人邮箱" prop="email">
        <el-input v-model="dataForm.email" placeholder="邮箱"></el-input>
      </el-form-item>
      <el-form-item label="负责人手机号" prop="mobile">
        <el-input v-model="dataForm.mobile" placeholder="手机号"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-if="sourceType == 'opc'">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="success" @click="linkOpcTest">测试链接</el-button>
      <el-button type="primary" @click="dataFormSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { isEmail, isMobile } from '@/utils/validate'
export default {
  data () {
    var validateEmail = (rule, value, callback) => {
      if (!isEmail(value)) {
        callback(new Error('邮箱格式错误'))
      } else {
        callback()
      }
    }
    var validateMobile = (rule, value, callback) => {
      if (!isMobile(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      listData: [],
      dataFieldList: [],
      linkDBOptions: [{
        value: 'mysql',
        label: 'mysql'
      }, {
        value: 'sql server',
        label: 'sql server'
      }, {
        value: 'oracle',
        label: 'oracle'
      }, {
        value: 'influxDB',
        label: 'influxDB'
      }],
      //新增--选择登录方式
      linkOpcOptions: [{
        value: 0,
        label: '匿名登录'
      },{
        value: 1,
        label: '证书登录'
      }],
      fileList: [],
      dataForm: {
        id: 0,
        name: '',
        alias: '',
        dataList:[
          {id:0,value:''}
        ],
        uid: '',
        dataFieldName: '',
        remark: '',

        linkDBType: '',
        dbname: '',
        ip: '',
        port: '',
        password: '',
        email: '',
        mobile: '',
        username: '',
      //  新增-opc登录方式
        linkOpcType: 0,
        cerLocalPath: '', //证书路径
        cerName: '' //证书名称
      },
      formData: undefined,
      data: undefined,
      filesPath: '',
      config: {},
      currOperator: '',

      dbDataRule: {
        linkDBType: [
          { required: true, message: '请选择数据库类型', trigger: 'blur' }
        ],
        ip: [
          { required: true, message: '请填写数据库ip', trigger: 'blur' }
        ],
        port: [
          { required: true, message: '请填写数据库端口', trigger: 'blur' }
        ],
        alias: [
          { required: true, message: '请填写数据源名称', trigger: 'blur' }
        ],
        dbname: [
          { required: true, message: '请填写数据库名称', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '请填写数据库用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请填写数据库密码', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          { validator: validateEmail, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { validator: validateMobile, trigger: 'blur' }
        ]
      },
      fileDataRule: {
        alias: [
          { required: true, message: '请填写数据源名称', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          { validator: validateEmail, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { validator: validateMobile, trigger: 'blur' }
        ]
      },
      sensorDataRule: {
        alias: [
          { required: true, message: '请填写数据源名称', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          { validator: validateEmail, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { validator: validateMobile, trigger: 'blur' }
        ]
      },
      tdDataRule: {
        alias: [
          { required: true, message: '请填写数据源名称', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          { validator: validateEmail, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { validator: validateMobile, trigger: 'blur' }
        ]
      },
      opcRule: {
        ip: [
          { required: true, message: '请填写ip', trigger: 'blur' }
        ],
        port: [
          { required: true, message: '请填写数端口', trigger: 'blur' }
        ],
        alias: [
          { required: true, message: '请填写数据源名称', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '请填写用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请填写密码', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          { validator: validateEmail, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { validator: validateMobile, trigger: 'blur' }
        ]
      }
    }
  },
  props: [
    "sourceType"
  ],
  mounted() {
    this.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
    this.config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    console.log(this.sourceType)
  },
  methods: {
    init (id) {
      this.dataForm.id = id || 0
      console.log(this.dataForm.id)
      if (this.dataForm.id !== 0) {
        if (this.sourceType == "数据库") {
          this.getRequest(
              "/dataSource/listById/db/?id=" + this.dataForm.id
          ).then((resp) => {
            if (resp.data) {
              console.log(resp.data);
              this.dataForm.alias = resp.data.alias
              this.dataForm.linkDBType = resp.data.db
              this.dataForm.dbname = resp.data.dbname
              this.dataForm.username = resp.data.username
              this.dataForm.port = resp.data.port
              this.dataForm.ip = resp.data.ip
              this.dataForm.email = resp.data.email
              this.dataForm.mobile = resp.data.mobile
              this.dataForm.url = "jdbc:mysql://" + this.dataForm.ip + ":" + this.dataForm.port + "/" + this.dataForm.dbname
                  + "?serverTimezone=UTC&useSSL=false"
            }
          });
        }
        if (this.sourceType == "文件") {
          this.getRequest(
              "/dataSource/listById/file/?id=" + this.dataForm.id
          ).then((resp) => {
            if (resp.data) {
              console.log(resp.data);
              // this.dataForm = resp.data.data
              this.dataForm.alias = resp.data.alias
              this.dataForm.email = resp.data.email
              this.dataForm.mobile = resp.data.mobile
            }
          });
        }
        if (this.sourceType == "传感器") {
          this.getRequest(
              "/dataSource/listById/sensor/?id=" + this.dataForm.id
          ).then((resp) => {
            if (resp.data) {
              console.log(resp.data);
              // this.dataForm = resp.data.data
              this.dataForm.alias = resp.data.alias
              this.dataForm.email = resp.data.email
              this.dataForm.mobile = resp.data.mobile
            }
          });
        }
        if (this.sourceType == "第三方") {
          this.getRequest(
              "/dataSource/listById/td/?id=" + this.dataForm.id
          ).then((resp) => {
            if (resp.data) {
              this.dataForm.alias = resp.data.alias
              this.dataForm.email = resp.data.email
              this.dataForm.mobile = resp.data.mobile
            }
          });
        }
        if (this.sourceType == "opc") {
          this.getRequest(
                  "/dataSource/listById/opc/?id=" + this.dataForm.id
          ).then((resp) => {
            if (resp.data) {
              console.log(resp.data);
              this.dataForm.alias = resp.data.alias
              this.dataForm.linkOpcType = resp.data.linkOpcType
              this.dataForm.username = resp.data.username
              this.dataForm.port = resp.data.port
              this.dataForm.ip = resp.data.ip
              this.dataForm.email = resp.data.email
              this.dataForm.mobile = resp.data.mobile
              this.dataForm.cerLocalPath = resp.data.cerLocalPath
            }
          });
        }
      }
      this.visible = true
      this.$refs.dataForm.resetFields()
      this.$emit("initData")
    },
    upFileSuccess(response, file, fileList) {
      console.log(document.getElementsByClassName("el-upload__input")[0].value)
      let fileNameSplit = file.name.split('.')
      let type = fileNameSplit[fileNameSplit.length - 1]
      let flag = 0
      let icon = ''
      if (type === 'txt') {
        flag = 0
        icon = 'iconfont icon-txtwenjian'
      } else if (type === 'docx'||type === 'doc') {
        flag = 1
        type='word'
        icon = 'iconfont icon-Word'
      } else if (type === 'xls'||type === 'xlsx') {
        flag = 2
        type='excel'
        icon = 'iconfont icon-Excel'
      } else if(type === 'pfx'){
        flag = 3
        type='pfx'
        icon = 'iconfont icon-txtwenjian'
      }
      else {
        this.$message({
          type: 'error',
          message: '不支持该类型文件'
        });
        return
      }
      this.data = {
        icon: icon,
        flag: flag,
        type: type,
        name: file.name,
        filesPath:this.filesPath,
        fileId:0,
      }
      this.fileList = fileList
      this.formData = new FormData()
      this.formData.append('file', file.raw)
      this.formData.append('filesPath', this.filesPath)
      this.formData.append('uid', this.currOperator)
      this.formData.append('type', type)
      // this.formData.append('email', this.dataForm.email)
      // this.formData.append('mobile', this.dataForm.mobile)
      this.formData.append('alias', this.dataForm.alias)

    },
    beforeRemove(file, fileList) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = (testmsg === "txt" || testmsg === "doc" || testmsg === "docx"|| testmsg === "xls"|| testmsg === "xlsx" );
      if(!extension)  return true;
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = (testmsg === "txt" || testmsg === "doc" || testmsg === "docx"|| testmsg === "xls"|| testmsg === "xlsx" );
      if (!extension ) {
        this.$message({
          message: "上传文件类型无效!只能上传txt/word/excel文件",
          type: "warning",
        });
      }
      return extension;
    },
    beforeRemovePfx(file,fileList){
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = (testmsg === "pfx");
      if(!extension)  return true;
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    beforeUploadPfx(file){
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = (testmsg === "pfx");
      if (!extension ) {
        this.$message({
          message: "上传文件类型无效!只能上传pfx文件",
          type: "warning",
        });
      }
      return extension;
    },
    removeData(item) {
      var index = this.dataForm.dataList.indexOf(item)
      if (index !== -1) {
        this.dataForm.dataList.splice(index, 1)
      }
    },
    addData() {
      this.dataForm.dataList.push({
        id:'',
        value:''
      });
    },
    linkDBTest() {
      let linkMessage;
      let data = {
        db: this.dataForm.linkDBType,
        ip: this.dataForm.ip,
        port: this.dataForm.port,
        dbname: this.dataForm.dbname,
        username: this.dataForm.username,
        password: this.dataForm.password,
      }
      this.postRequest("/addDataAccessTask/linkDbTest", data).then(response => {
        linkMessage = response.data
        this.$alert(linkMessage, '测试结果', {
          confirmButtonText: '确定',
        });
      }).then(error => {
        // console.log(error)
      })
    },
    //新增：opc服务器链接测试 -- start
    linkOpcTest() {
      if(this.dataForm.linkOpcType == 0){ //匿名登录
        let linkMessage;
        let data = {
          linkOpcType: this.dataForm.linkOpcType,
          ip: this.dataForm.ip,
          port: this.dataForm.port,
          username: this.dataForm.username,
          password: this.dataForm.password,
        }
        this.postRequest("/addDataAccessTask/linkOpcTest", data).then(response => {
          linkMessage = response.data
          this.$alert(linkMessage, '测试结果', {
            confirmButtonText: '确定',
          });
        }).then(error => {
          // console.log(error)
        })
      }
      else if(this.dataForm.linkOpcType == 1){
        this.formData.append('ip', this.dataForm.ip);
        this.formData.append('port', this.dataForm.port);
        this.formData.append('linkOpcType', this.dataForm.linkOpcType);
        this.formData.append('username', this.dataForm.username);
        this.postRequest("/addDataAccessTask/linkOpcPfxTest",this.formData, this.config).then(response=> {
          console.log(response)
          // linkMessage = response.data
          this.$alert(response.data, '测试结果', {
                    confirmButtonText: '确定',}
          );
        }).then(error => {
          // console.log(error)
        })
      }

    },
    //新增：opc服务器链接测试 -- end
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          if (this.sourceType == "数据库") {
            let linkMessage;
            let data = {
              db: this.dataForm.linkDBType,
              ip: this.dataForm.ip,
              port: this.dataForm.port,
              dbname: this.dataForm.dbname,
              username: this.dataForm.username,
              password: this.dataForm.password,
              email: this.dataForm.email,
              mobile: this.dataForm.mobile,
              alias: this.dataForm.alias,
              uid: this.currOperator
            }
            if (this.dataForm.id == 0) {
              this.postRequest("/addDataAccessTask/linkdb", data).then(response => {
                linkMessage = response.data
                this.$alert(linkMessage, '添加结果', {
                  confirmButtonText: '确定',
                  callback: action => {
                    if (linkMessage == "连接成功") {
                      this.visible = false
                      this.$refs.dataForm.resetFields()
                      this.$emit("initData")
                    }
                  }
                });
              }).then(error => {
                // console.log(error)
              })
            } else {
              data.id = this.dataForm.id
              this.postRequest("/addDataAccessTask/updateLinkdb ", data).then(response => {
                linkMessage = response.data.msg
                this.$alert(linkMessage, '修改结果', {
                  confirmButtonText: '确定',
                  callback: action => {
                    if (linkMessage == "连接成功") {
                      this.visible = false
                      this.$refs.dataForm.resetFields()
                      this.$emit("initData")
                    }
                  }
                });
              }).then(error => {
                // console.log(error)
              })
            }

          }
          if (this.sourceType == "文件") {
            if (this.dataForm.id == 0) {
              this.formData.append('email', this.dataForm.email)
              this.formData.append('mobile', this.dataForm.mobile)
              this.postRequest("/addDataAccessTask/fileUploadMinio",this.formData, this.config).then(response=> {
                console.log(response)
                this.$alert(response.status == 200 ? "上传成功" : "上传失败", '上传结果', {
                  confirmButtonText: '确定',
                  callback: action => {
                    if (response.status == 200) {
                      this.visible = false
                      this.$refs.dataForm.resetFields()
                      this.$emit("initData")
                    }
                  }
                });
              }).then(error => {
                // console.log(error)
              })
            } else {
              let data = {
                id: this.dataForm.id,
                alias: this.dataForm.alias,
                email: this.dataForm.email,
                mobile: this.dataForm.mobile
              }
              this.postRequest("/addDataAccessTask/updateFile", data).then(response=> {
                console.log(response)
                this.$alert(response.status == 200 ? "修改成功" : "修改失败", '修改结果', {
                  confirmButtonText: '确定',
                  callback: action => {
                    if (response.status == 200) {
                      this.visible = false
                      this.$refs.dataForm.resetFields()
                      this.$emit("initData")
                    }
                  }
                });
              }).then(error => {
                // console.log(error)
              })
            }

          }
          if (this.sourceType == "传感器") {
            if (this.dataForm.id == 0) {
              let data={
                name:this.dataForm.name,
                info:this.dataForm.dataList[0].value,
                uid:this.currOperator,
                sensorId:0,
                email: this.dataForm.email,
                mobile: this.dataForm.mobile,
                alias: this.dataForm.alias
              }
              for (let i = 1; i < this.dataForm.dataList.length; i++) {
                data.info+='||'+this.dataForm.dataList[i].value
              }
              this.postRequest("/addDataAccessTask/addSensor",data).then(response=> {
                this.$alert(response.status == 200 ? "添加成功" : "添加失败", '添加结果', {
                  confirmButtonText: '确定',
                  callback: action => {
                    if (response.status == 200) {
                      this.visible = false
                      this.$refs.dataForm.resetFields()
                      this.$emit("initData")
                    }
                  }
                });
              }).then(error => {
                // console.log(error)
              })
            } else {
              let data={
                id: this.dataForm.id,
                email: this.dataForm.email,
                mobile: this.dataForm.mobile,
                alias: this.dataForm.alias
              }
              this.postRequest("/addDataAccessTask/updateSensor",data).then(response=> {
                this.$alert(response.status == 200 ? "修改成功" : "修改失败", '修改结果', {
                  confirmButtonText: '确定',
                  callback: action => {
                    if (response.status == 200) {
                      this.visible = false
                      this.$refs.dataForm.resetFields()
                      this.$emit("initData")
                    }
                  }
                });
              }).then(error => {
                // console.log(error)
              })
            }
          }
          if (this.sourceType == "第三方") {
            if (this.dataForm.id == 0) {
              let data={
                name:this.dataForm.name,
                dataList:this.dataForm.dataList[0].value,
                uid:this.currOperator,
                thirdPartyId:0,
                email: this.dataForm.email,
                mobile: this.dataForm.mobile,
                alias: this.dataForm.alias
              }
              for (let i = 1; i < this.dataForm.dataList.length; i++) {
                data.dataList+='||'+this.dataForm.dataList[i].value
              }
              this.postRequest("/addDataAccessTask/addThirdParty",data).then(response=> {
                this.$alert(response.status == 200 ? "添加成功" : "添加失败", '添加结果', {
                  confirmButtonText: '确定',
                  callback: action => {
                    if (response.status == 200) {
                      this.visible = false
                      this.$refs.dataForm.resetFields()
                      this.$emit("initData")
                    }
                  }
                });
              }).then(error => {
                // console.log(error)
              })
            } else {
              let data={
                id: this.dataForm.id,
                email: this.dataForm.email,
                mobile: this.dataForm.mobile,
                alias: this.dataForm.alias
              }
              this.postRequest("/addDataAccessTask/updateTd",data).then(response=> {
                this.$alert(response.status == 200 ? "修改成功" : "修改失败", '修改结果', {
                  confirmButtonText: '确定',
                  callback: action => {
                    if (response.status == 200) {
                      this.visible = false
                      this.$refs.dataForm.resetFields()
                      this.$emit("initData")
                    }
                  }
                });
              }).then(error => {
                // console.log(error)
              })
            }

          }
          if (this.sourceType == "opc") {
            if (this.dataForm.id == 0) {
              console.log("linkOpcType"+this.dataForm.linkOpcType);
              if(this.dataForm.linkOpcType == 0){
                let linkMessage;
                let data = {
                  linkOpcType: this.dataForm.linkOpcType,
                  ip: this.dataForm.ip,
                  port: this.dataForm.port,
                  username: this.dataForm.username,
                  password: this.dataForm.password,
                  email: this.dataForm.email,
                  mobile: this.dataForm.mobile,
                  alias: this.dataForm.alias,
                  cername: this.dataForm.cerName,
                  cerLocalPath: this.dataForm.cerLocalPath,
                  uid: this.currOperator
                }
                this.postRequest("/addDataAccessTask/linkOpc", data).then(response => {
                  linkMessage = response.data
                  this.$alert(linkMessage, '添加结果', {
                    confirmButtonText: '确定',
                    callback: action => {
                      if (linkMessage == "连接成功") {
                        this.visible = false
                        this.$refs.dataForm.resetFields()
                        this.$emit("initData")
                      }
                    }
                  });
                }).then(error => {
                  // console.log(error)
                })
              }
              else if(this.dataForm.linkOpcType == 1){
                this.formData.append('ip', this.dataForm.ip);
                this.formData.append('port', this.dataForm.port);
                this.formData.append('linkOpcType', this.dataForm.linkOpcType);
                this.formData.append('username', this.dataForm.username);
                this.formData.append('email', this.dataForm.email);
                this.formData.append('mobile', this.dataForm.mobile);
                this.postRequest("/addDataAccessTask/pfxFileUploadMinio",this.formData, this.config).then(response=> {
                  console.log(response)

                  this.$alert(response.status == 200 ? "添加成功" : "添加失败", '添加结果', {
                    confirmButtonText: '确定',
                    callback: action => {
                      if (response.status == 200) {
                        this.visible = false
                        this.$refs.dataForm.resetFields()
                        this.$emit("initData")
                      }
                    }
                  });
                }).then(error => {
                  // console.log(error)
                })
              }

            } else {
              data.id = this.dataForm.id
              this.postRequest("/addDataAccessTask/updateLinkopc ", data).then(response => {
                linkMessage = response.data.msg
                this.$alert(linkMessage, '修改结果', {
                  confirmButtonText: '确定',
                  callback: action => {
                    if (linkMessage == "修改成功") {
                      this.visible = false
                      this.$refs.dataForm.resetFields()
                      this.$emit("initData")
                    }
                  }
                });
              }).then(error => {
                // console.log(error)
              })
            }

          }
        }
      })
    }
  }
}
</script>
